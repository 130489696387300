<template>
	<div style = "height:100%; background-color: #ffffff" class="d-flex justify-content-center align-items-center"> 
		<div class="container">
			<p class="logo"><a href="/"><img src="/static/img/flowe.png" class="imglogo"></a></p>
			<div>
				<div class="pagetitle2">
					<h2 class="text-center">Oops! You have to sign in first.</h2>
					<p class="text-center">The page you are trying to access is only available to registered users. Click below to sign in, or sign up for an account if you don’t already have one. It’s free!.</p>
				</div>
				<div class="form-group responsepanel text-center">
					<a href="/sign-up" class="btn bt-xs btn-secondary-light">Signed me up</a> <a href="/sign-in" class="btn bt-xs btn-primary">Sign in</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript"></script>

<script>
	export default{
		name:'401error',
		data(){
       		return{
       			redirect : ""
       		}
       	},
		mounted() { 
			$('#preloader').addClass('hide')
		}


	}
</script>